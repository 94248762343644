exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-informational-partners-jsx": () => import("./../../../src/pages/informational-partners.jsx" /* webpackChunkName: "component---src-pages-informational-partners-jsx" */),
  "component---src-pages-kazky-jsx": () => import("./../../../src/pages/kazky.jsx" /* webpackChunkName: "component---src-pages-kazky-jsx" */),
  "component---src-pages-mediakit-jsx": () => import("./../../../src/pages/mediakit.jsx" /* webpackChunkName: "component---src-pages-mediakit-jsx" */),
  "component---src-pages-pisni-epichni-pisni-balady-index-jsx": () => import("./../../../src/pages/pisni/epichni-pisni/balady/index.jsx" /* webpackChunkName: "component---src-pages-pisni-epichni-pisni-balady-index-jsx" */),
  "component---src-pages-pisni-epichni-pisni-dumy-index-jsx": () => import("./../../../src/pages/pisni/epichni-pisni/dumy/index.jsx" /* webpackChunkName: "component---src-pages-pisni-epichni-pisni-dumy-index-jsx" */),
  "component---src-pages-pisni-epichni-pisni-index-jsx": () => import("./../../../src/pages/pisni/epichni-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-epichni-pisni-index-jsx" */),
  "component---src-pages-pisni-epichni-pisni-istorychni-pisni-index-jsx": () => import("./../../../src/pages/pisni/epichni-pisni/istorychni-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-epichni-pisni-istorychni-pisni-index-jsx" */),
  "component---src-pages-pisni-epichni-pisni-pisni-khroniky-index-jsx": () => import("./../../../src/pages/pisni/epichni-pisni/pisni-khroniky/index.jsx" /* webpackChunkName: "component---src-pages-pisni-epichni-pisni-pisni-khroniky-index-jsx" */),
  "component---src-pages-pisni-index-jsx": () => import("./../../../src/pages/pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-index-jsx" */),
  "component---src-pages-pisni-obriadovi-pisni-index-jsx": () => import("./../../../src/pages/pisni/obriadovi-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-obriadovi-pisni-index-jsx" */),
  "component---src-pages-pisni-obriadovi-pisni-kalendarno-obriadovi-pisni-index-jsx": () => import("./../../../src/pages/pisni/obriadovi-pisni/kalendarno-obriadovi-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-obriadovi-pisni-kalendarno-obriadovi-pisni-index-jsx" */),
  "component---src-pages-pisni-obriadovi-pisni-kalendarno-obriadovi-pisni-koliadky-ta-shchedrivky-index-jsx": () => import("./../../../src/pages/pisni/obriadovi-pisni/kalendarno-obriadovi-pisni/koliadky-ta-shchedrivky/index.jsx" /* webpackChunkName: "component---src-pages-pisni-obriadovi-pisni-kalendarno-obriadovi-pisni-koliadky-ta-shchedrivky-index-jsx" */),
  "component---src-pages-pisni-obriadovi-pisni-kalendarno-obriadovi-pisni-kupalski-pisni-index-jsx": () => import("./../../../src/pages/pisni/obriadovi-pisni/kalendarno-obriadovi-pisni/kupalski-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-obriadovi-pisni-kalendarno-obriadovi-pisni-kupalski-pisni-index-jsx" */),
  "component---src-pages-pisni-obriadovi-pisni-kalendarno-obriadovi-pisni-rusalni-ta-petrivchani-pisni-index-jsx": () => import("./../../../src/pages/pisni/obriadovi-pisni/kalendarno-obriadovi-pisni/rusalni-ta-petrivchani-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-obriadovi-pisni-kalendarno-obriadovi-pisni-rusalni-ta-petrivchani-pisni-index-jsx" */),
  "component---src-pages-pisni-obriadovi-pisni-kalendarno-obriadovi-pisni-vesnianky-index-jsx": () => import("./../../../src/pages/pisni/obriadovi-pisni/kalendarno-obriadovi-pisni/vesnianky/index.jsx" /* webpackChunkName: "component---src-pages-pisni-obriadovi-pisni-kalendarno-obriadovi-pisni-vesnianky-index-jsx" */),
  "component---src-pages-pisni-obriadovi-pisni-kalendarno-obriadovi-pisni-zhnyvarski-pisni-index-jsx": () => import("./../../../src/pages/pisni/obriadovi-pisni/kalendarno-obriadovi-pisni/zhnyvarski-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-obriadovi-pisni-kalendarno-obriadovi-pisni-zhnyvarski-pisni-index-jsx" */),
  "component---src-pages-pisni-obriadovi-pisni-rodynno-obriadovi-pisni-holosinnia-index-jsx": () => import("./../../../src/pages/pisni/obriadovi-pisni/rodynno-obriadovi-pisni/holosinnia/index.jsx" /* webpackChunkName: "component---src-pages-pisni-obriadovi-pisni-rodynno-obriadovi-pisni-holosinnia-index-jsx" */),
  "component---src-pages-pisni-obriadovi-pisni-rodynno-obriadovi-pisni-index-jsx": () => import("./../../../src/pages/pisni/obriadovi-pisni/rodynno-obriadovi-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-obriadovi-pisni-rodynno-obriadovi-pisni-index-jsx" */),
  "component---src-pages-pisni-obriadovi-pisni-rodynno-obriadovi-pisni-vesilni-pisni-index-jsx": () => import("./../../../src/pages/pisni/obriadovi-pisni/rodynno-obriadovi-pisni/vesilni-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-obriadovi-pisni-rodynno-obriadovi-pisni-vesilni-pisni-index-jsx" */),
  "component---src-pages-pisni-pisni-literaturnoho-pokhodzhennia-index-jsx": () => import("./../../../src/pages/pisni/pisni-literaturnoho-pokhodzhennia/index.jsx" /* webpackChunkName: "component---src-pages-pisni-pisni-literaturnoho-pokhodzhennia-index-jsx" */),
  "component---src-pages-pisni-rodynno-pobutova-liryka-index-jsx": () => import("./../../../src/pages/pisni/rodynno-pobutova-liryka/index.jsx" /* webpackChunkName: "component---src-pages-pisni-rodynno-pobutova-liryka-index-jsx" */),
  "component---src-pages-pisni-rodynno-pobutova-liryka-kolyskovi-pisni-index-jsx": () => import("./../../../src/pages/pisni/rodynno-pobutova-liryka/kolyskovi-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-rodynno-pobutova-liryka-kolyskovi-pisni-index-jsx" */),
  "component---src-pages-pisni-rodynno-pobutova-liryka-pisni-pro-kokhannia-index-jsx": () => import("./../../../src/pages/pisni/rodynno-pobutova-liryka/pisni-pro-kokhannia/index.jsx" /* webpackChunkName: "component---src-pages-pisni-rodynno-pobutova-liryka-pisni-pro-kokhannia-index-jsx" */),
  "component---src-pages-pisni-rodynno-pobutova-liryka-pisni-pro-rodynne-zhyttia-index-jsx": () => import("./../../../src/pages/pisni/rodynno-pobutova-liryka/pisni-pro-rodynne-zhyttia/index.jsx" /* webpackChunkName: "component---src-pages-pisni-rodynno-pobutova-liryka-pisni-pro-rodynne-zhyttia-index-jsx" */),
  "component---src-pages-pisni-rodynno-pobutova-liryka-zhartivlyvi-pisni-index-jsx": () => import("./../../../src/pages/pisni/rodynno-pobutova-liryka/zhartivlyvi-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-rodynno-pobutova-liryka-zhartivlyvi-pisni-index-jsx" */),
  "component---src-pages-pisni-sotsialno-pobutovi-pisni-burlatski-pisni-index-jsx": () => import("./../../../src/pages/pisni/sotsialno-pobutovi-pisni/burlatski-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-sotsialno-pobutovi-pisni-burlatski-pisni-index-jsx" */),
  "component---src-pages-pisni-sotsialno-pobutovi-pisni-chumatski-pisni-index-jsx": () => import("./../../../src/pages/pisni/sotsialno-pobutovi-pisni/chumatski-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-sotsialno-pobutovi-pisni-chumatski-pisni-index-jsx" */),
  "component---src-pages-pisni-sotsialno-pobutovi-pisni-index-jsx": () => import("./../../../src/pages/pisni/sotsialno-pobutovi-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-sotsialno-pobutovi-pisni-index-jsx" */),
  "component---src-pages-pisni-sotsialno-pobutovi-pisni-kozatski-pisni-index-jsx": () => import("./../../../src/pages/pisni/sotsialno-pobutovi-pisni/kozatski-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-sotsialno-pobutovi-pisni-kozatski-pisni-index-jsx" */),
  "component---src-pages-pisni-sotsialno-pobutovi-pisni-kripatski-pisni-index-jsx": () => import("./../../../src/pages/pisni/sotsialno-pobutovi-pisni/kripatski-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-sotsialno-pobutovi-pisni-kripatski-pisni-index-jsx" */),
  "component---src-pages-pisni-sotsialno-pobutovi-pisni-naimytski-ta-zarobitchanski-pisni-index-jsx": () => import("./../../../src/pages/pisni/sotsialno-pobutovi-pisni/naimytski-ta-zarobitchanski-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-sotsialno-pobutovi-pisni-naimytski-ta-zarobitchanski-pisni-index-jsx" */),
  "component---src-pages-pisni-sotsialno-pobutovi-pisni-rekrutski-ta-soldatski-pisni-index-jsx": () => import("./../../../src/pages/pisni/sotsialno-pobutovi-pisni/rekrutski-ta-soldatski-pisni/index.jsx" /* webpackChunkName: "component---src-pages-pisni-sotsialno-pobutovi-pisni-rekrutski-ta-soldatski-pisni-index-jsx" */),
  "component---src-pages-policy-jsx": () => import("./../../../src/pages/policy.jsx" /* webpackChunkName: "component---src-pages-policy-jsx" */),
  "component---src-pages-pryslivia-ta-prykazky-index-jsx": () => import("./../../../src/pages/pryslivia-ta-prykazky/index.jsx" /* webpackChunkName: "component---src-pages-pryslivia-ta-prykazky-index-jsx" */),
  "component---src-pages-skoromovky-jsx": () => import("./../../../src/pages/skoromovky.jsx" /* webpackChunkName: "component---src-pages-skoromovky-jsx" */),
  "component---src-pages-smikhovyny-jsx": () => import("./../../../src/pages/smikhovyny.jsx" /* webpackChunkName: "component---src-pages-smikhovyny-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-joke-jsx": () => import("./../../../src/templates/joke.jsx" /* webpackChunkName: "component---src-templates-joke-jsx" */),
  "component---src-templates-proverb-jsx": () => import("./../../../src/templates/proverb.jsx" /* webpackChunkName: "component---src-templates-proverb-jsx" */),
  "component---src-templates-song-jsx": () => import("./../../../src/templates/song.jsx" /* webpackChunkName: "component---src-templates-song-jsx" */),
  "component---src-templates-tale-jsx": () => import("./../../../src/templates/tale.jsx" /* webpackChunkName: "component---src-templates-tale-jsx" */)
}

