import * as React from 'react';

import Link from '../components/Link';
import FacebookIcon from '../components/icons/FacebookIcon';
import TelegramIcon from '../components/icons/TelegramIcon';
import TwitterIcon from '../components/icons/TwitterIcon';
import ThumbsUpIcon from '../components/icons/ThumbsUpIcon';

import Logo from '../images/logo.svg';

const Footer = () => {
  return (
    <>
      <footer className="bg-black text-white pt-16">
        <div className="container-lg">
          <div className="row">
            <div className="col-full md:col-6">
              <Link to="/" className="inline-block mb-4">
                <img src={Logo} alt="Лого – Перлини української народної творчості" />
                <span className="sr-only">Українська народна творчість – Головна</span>
              </Link>
              <p className="typo-body mb-4">
                Перлини української народної творчості
              </p>
              <div className="flex items-center mt-8">
                <Link to="https://www.facebook.com/folklore.com.ua" className="mr-4">
                  <span className="sr-only">
                    Facebook
                  </span>
                  <FacebookIcon className="h-8 w-8" />
                </Link>
                <Link to="https://t.me/folklorecomua" className="mr-4">
                  <span className="sr-only">
                    Telegram
                  </span>
                  <TelegramIcon className="h-8 w-8" />
                </Link>
                <Link to="https://twitter.com/folklorecomua">
                  <span className="sr-only">
                    Twitter
                  </span>
                  <TwitterIcon className="h-8 w-8" />
                </Link>
              </div>
            </div>
            <div className="col-full md:col-3 mt-12 md:mt-0">
              <h3 className="typo-h4 mb-4">
                проєкт
              </h3>
              <div className="mb-2">
                <Link to="/about/" className="typo-body">
                  про проєкт
                </Link>
              </div>
              <div className="mb-2">
                <Link to="/support/" className="typo-body">
                  підтримати проєкт
                  <ThumbsUpIcon className="inline h-5 w-5 ml-2 text-red-500" />
                </Link>
              </div>
              <div>
                <Link to="/blog/" className="typo-body">
                  блог
                </Link>
              </div>
              <div>
                <Link to="/informational-partners/" className="typo-body">
                  партнери
                </Link>
              </div>
            </div>
            <div className="col-full md:col-3 mt-12 md:mt-0">
              <h3 className="typo-h4 mb-4">
                категорії
              </h3>
              <div className="mb-2">
                <Link to="/pisni/" className="typo-body">
                  пісні
                </Link>
              </div>
              <div className="mb-2">
                <Link to="/kazky/" className="typo-body">
                  казки
                </Link>
              </div>
              <div className="mb-2">
                <Link to="/smikhovyny/" className="typo-body">
                  сміховини
                </Link>
              </div>
              <div className="mb-2">
                <Link to="/pryslivia-ta-prykazky/" className="typo-body">
                  прислів'я та приказки
                </Link>
              </div>
              <div>
                <Link to="/skoromovky/" className="typo-body">
                  скоромовки
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center justify-between py-4 mt-12 border-t border-gray-400">
            <small className="typo-small">
              &copy; 2022 – 2024 Перлини української народної творчості.
            </small>
            <Link to="/policy/" className="typo-small">
              Політика конфіденційності
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
